import { Text, Box } from 'theme-ui'
import React, { FC } from 'react'
import ShippingLabel from '~/components/parameters/shipping-label'
import useProductAvailability from '~/hooks/use-product-availability'
import { useProductDetail } from '~/containers/product-detail-v2/Provider'
import { Tag } from '~/componentsV2/tag'
import { FONTS } from '~/styled/components/typography'
import OrderSwatch, { OrderSwatchProps } from '../product-detail-panel/order-swatch'
import { RugsCustomSize } from '~/componentsV2/rug-custom-size'
import { useCustomerContext } from '~/context/customer-context'

const ValueTypes = {
  DEFAULT_TITLE: 'Default Title',
}

type Props = Record<string, any>

const SelectParameter: FC<Props> = ({
  option,
  onChange,
  value,
  options,
  showAddSwatch = true,
  label,
  stockControl,
}) => {
  const {
    product,
    productVariant: variant,
    productSwatches,
    productUtils: { isUpholstery },
    flyOutControl,
  } = useProductDetail()

  const { isTrade } = useCustomerContext()

  const { getColorParameterTitle } = useProductAvailability({
    product,
    variant,
  })

  const showSwatch = productSwatches.length > 0 && !product.isCustomQuickship && showAddSwatch

  const orderWatchProps = {} as OrderSwatchProps

  if (!option || !option?.values) {
    return null
  }

  // if (showSwatch) {
  //   orderWatchProps.onCustomCart = () => {
  //     flyOutControl.setFlyOpen(true)
  //   }
  // }

  if (value === ValueTypes.DEFAULT_TITLE) {
    return null
  }

  if (option?.values?.length === 1 && !showSwatch) {
    return (
      <Box>
        <Box
          sx={{
            color: 'zambezi',
            height: 64,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          className="select-parameter"
        >
          <Text
            sx={{
              display: 'inline-flex',
              justifyContent: 'start',
              textTransform: 'uppercase',
            }}
            as="span"
          >
            <span className="label">{option.label} </span>
            <span className="separator">|</span>
            <Text
              as="span"
              sx={{
                color: 'text',
                ml: 'sm',
              }}
            >
              {option.values[0]}
            </Text>
          </Text>
        </Box>

        {options?.length === 1 ? <ShippingLabel>{getColorParameterTitle()}</ShippingLabel> : null}
      </Box>
    )
  }

  return (
    <Box
      sx={{
        color: 'zambezi',
      }}
      className="select-parameter multiple"
    >
      <Box className="group">
        <span className="label">{option.label}</span>
        <span className="separator">|</span>
        <span>{label || value}</span>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '10px',
          mt: '10px',
        }}
      >
        <>
          {option?.values.map((item: any) => (
            <Tag
              key={item}
              active={value === item}
              style={{
                padding: '3px',
                cursor: 'pointer',
              }}
              bgColor="transparent"
              onClick={() => onChange(option, item)}
            >
              <Box
                sx={{
                  fontFamily: FONTS.Helvetica,
                  padding: '5px 10px',
                  backgroundColor: '#EDEDED',
                }}
              >
                {item}
              </Box>
            </Tag>
          ))}

          {productSwatches.length > 0 && !product.isCustomQuickship && showAddSwatch && (
            <OrderSwatch
              {...orderWatchProps}
              product={product}
              variant={variant}
              swatches={productSwatches}
            />
          )}
          {product.productType?.toLowerCase().includes('rug') &&
          isTrade &&
          option.label === 'Size' ? (
            <RugsCustomSize product={product} productVariant={variant} />
          ) : null}
        </>
      </Box>

      {/* <Select
        sx={{
          width: ['175px', '225px', null, null, '275px'],
          textAlign: 'center',
          height: 42,
          marginLeft: ['25px', null, '125px', '50px', '20px'],
          color: 'text',
          // mr: 25,
          // mt: '6px',
          svg: {
            marginTop: 5,
          },
        }}
        name={option.name}
        options={option.values}
        onChange={e => onChange(option, e.target.value)}
        value={value}
      /> */}
    </Box>
  )
}

export default SelectParameter
