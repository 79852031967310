/** @jsx jsx */
import { jsx, Box, SxStyleProp } from 'theme-ui'
import { FC, useState, Fragment } from 'react'
import Slider, { Settings } from 'react-slick'
import ArrowSecondary from '~/components/arrow-secondary'
import SlickDots from '~/slick-dots'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import ImageWrapper from '~/components/image-wrapper'
import { ProductItemComponentType, ProductVariantType } from '~/@types/models'
import ProductItemSecondary from '../product-item-secondary'
import { NavigationButton } from '~/componentsV2/navigationButton'
import { RiArrowLeftSLine, RiArrowRightSLine } from '@remixicon/react'
import { useTheme } from 'styled-components'
import { useMediaQuery } from 'react-responsive'
type Props = {
  data: Array<{
    product: ProductItemComponentType
  }>
  GridItem: typeof ProductItemSecondary
  gridProps?: Record<string, any>
  sx?: SxStyleProp
  carouselOnMobile?: boolean
  gridItemProps?: object
  selectedOption?: string
  trackLocation?: string
}

const ProductFieldCarousel: FC<Props> = props => {
  const { selectedOption, GridItem, data, gridItemProps = {}, trackLocation } = props

  const theme = useTheme()

  const isDesktop = useMediaQuery({ query: theme.mediaQueries.desktop })

  const handleArrowClick = () => {
    if (typeof window !== 'undefined') {
      window.hj('event', `Product Carousel Scroll - ${trackLocation || ''}`)
    }
  }

  const prevArrow = (
    <NavigationButton action="prev" spacing={-30}>
      <RiArrowLeftSLine />
    </NavigationButton>
  )
  const nextArrow = (
    <NavigationButton action="next" spacing={-30}>
      <RiArrowRightSLine />
    </NavigationButton>
  )

  const [settings] = useState<Settings>({
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow,
    nextArrow,
    arrows: true,
    dots: false,
    centerMode: false,
    appendDots: SlickDots,
    // adaptiveHeight: true,
    infinite: false,
    swipe: true,
    swipeToSlide: true,
    afterChange(currentSlide) {
      handleArrowClick()
    },
    onSwipe: () => {
      handleArrowClick()
    },
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: theme.maxBreakPoints.tablet,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: theme.maxBreakPoints.mobile,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          // adaptiveHeight: true,
          // centerMode: true,
        },
      },
    ],
  })

  // SSR hack - Slider get rendered bad when combining ssr + client side
  if (typeof window === 'undefined') {
    return null
  }

  const imageComponent = data.map(productItem => {
    const product = productItem.product
    let image
    if (selectedOption) {
      const selectedTokens = selectedOption.toLowerCase().split(' ')

      let maxMatchCount = 0
      let bestVariant: ProductVariantType | null = null

      product.variants.forEach(variant => {
        variant.selectedOptions.forEach(option => {
          const optionTokens = option.value.toLowerCase().split(' ')

          const matchCount = selectedTokens.reduce((count, token) => {
            return count + (optionTokens.includes(token) ? 1 : 0)
          }, 0)

          if (matchCount > maxMatchCount) {
            maxMatchCount = matchCount
            bestVariant = variant
          }
        })
      })

      if (bestVariant) {
        image = (bestVariant as ProductVariantType).image
      }
    }

    return <GridItem image={image} {...productItem} {...gridItemProps} />
  })
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <Fragment>
      <Box
        sx={{
          overflow: ['visible', 'hidden'],
          img: {
            maxHeight: '100%',
          },
          '.slick-slider': {
            touchAction: 'auto !important',
          },
          '.slick-arrow': {
            transition: 'color .3s',
          },
          '.slick-disabled': {
            color: 'rgba(16, 16, 16, 0.3)',
          },
          '.slick-track': {
            float: 'left',
          },
          // '.slick-list': {
          //   padding: ['0 20% 0 0 !important', 'padding: 0px 50px'],
          //   overflow: ['visible', 'hidden'],
          // },
        }}
      >
        <Box
          sx={{
            px: [0, 38],
            order: isDesktop ? 1 : 'initial',
          }}
        >
          <Slider {...settings}>{imageComponent}</Slider>
        </Box>
      </Box>
    </Fragment>
  )
}

export default ProductFieldCarousel
